.bodyx {
  width: auto;
}

.boxx {
  width: 30px;
  height: 30px;
  /* border-radius: 25%; */
  margin: 5px;
  float: left;
  border: 1px solid #000;
}

@media (max-width: 767px) {
  .mobifluid {
    margin-top: -60px;
  }
}

@media (max-width: 500px) {
  .mobifluid {
    margin-top: -80px;
  }
}

@media (max-width: 450px) {
  .mobifluid {
    margin-top: -120px;
  }
}

@media (max-width: 400px) {
  .mobifluid {
    margin-top: -160px;
  }
}

@media (max-width: 350px) {
  .mobifluid {
    margin-top: -200px;
  }
}

@media (max-width: 300px) {
  .mobifluid {
    margin-top: -220px;
  }
}
